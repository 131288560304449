import React, { useRef, useState } from 'react';
import {
  Recipe as RecipeType,
  Unit,
} from '../types';
import './Recipe.css';

type Props = {
  recipe: RecipeType;
};

const Recipe = ({ recipe }: Props) => {
  const [multiplier, setMultiplier] = useState<number>(1);
  const [edit, setEdit] = useState<number>(-1);
  const [inputValue, setInputValue] = useState<string>('0');
  const inputRef = useRef();

  const calculateAmount = (amount: number): string => (multiplier * amount).toFixed(2);

  const closeInput = () => setEdit(-1);

  const printUnit = (unit: Unit): string => {
    let result = '';
    switch(unit) {
      case 'grams':
        result = 'gr';
        break;
      case 'milliliters':
        result = 'ml';
        break;
      case 'spoon':
        result = 'cucharada';
        break;
      case 'part':
        result = 'Parte';
        break;
      case 'cup':
        result = 'Taza';
        break;
      case 'pinch':
        result = 'Pizca';
        break;
    };
    return result;
  };

  const inputClickHandler = (idx: number) => {
    if (idx === recipe.ingredients.length) { // Total gr
      setInputValue(calculateTotalGr);
    } else {
      setInputValue(calculateAmount(recipe.ingredients[idx].amount));
    }
    setEdit(idx);
  };

  const totalGrNumber = (): number => recipe
    .ingredients
    .filter((ingredient) => ['grams', 'milliliters'].includes(ingredient.unit))
    .reduce((acc, ingredient) => (acc + ingredient.amount), 0);

  const recalculateMultiplier = (value: string, idx: number): void => {
    let original: number = 0;
    if (idx === recipe.ingredients.length) { // Total gr
      original = totalGrNumber();
    } else {
      original = recipe.ingredients[idx].amount;
    }
    const newMultiplier = Number(value) / original;
    setMultiplier(newMultiplier);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, idx: number) => {
    if (event.key === 'Enter') {
      recalculateMultiplier(event.currentTarget.value, idx);
      closeInput();
    } else if (event.key === 'Escape') {
      closeInput();
    }
  };

  const calculateTotalGr = () => {
    const sum = totalGrNumber();
    return calculateAmount(sum);
  };

  return (
    <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm recipe-container">
      <div>
        <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">{recipe.name}</h5>

        <table className="min-w-full text-center" style={{ tableLayout: 'fixed' }}>
          <thead className="bg-white border-b">
            <tr>
              <th
                className="text-sm font-medium text-gray-900 px-6 py-2"
                style={{ width: '50%' }}
              >
                Ingrediente
              </th>
              <th
                className="text-sm font-medium text-gray-900 px-6 py-2"
                style={{ width: '50%' }}
              >
                Cantidad
              </th>
            </tr>
          </thead>
          <tbody>
            {recipe.ingredients.map((ingredient, idx) => (
              <tr className="bg-white border-b">
                <th className="text-sm font-medium text-gray-900 px-6 py-2 whitespace-nowrap">{ingredient.name}</th>
                <td className="text-sm font-light text-gray-900 px-6 py-2 whitespace-nowrap">
                  {
                    idx === edit ? (
                      <input
                        autoFocus
                        value={inputValue}
                        onChange={(event) => setInputValue(event.target.value)}
                        onKeyDown={(event) => handleKeyDown(event, idx)}
                        ref={inputRef as any}
                        onBlur={closeInput}
                        style={{ width: '80%', textAlign: 'center' }}
                      />
                    ) : (
                      <b onClick={() => inputClickHandler(idx)}>{` ${calculateAmount(ingredient.amount)} ${printUnit(ingredient.unit)}`}</b>
                    )
                  }
                </td>
              </tr>
            ))}
            <tr>
              <th>Total</th>
              <td>
                {
                  recipe.ingredients.length === edit ? (
                    <input
                      autoFocus
                      value={inputValue}
                      onChange={(event) => setInputValue(event.target.value)}
                      onKeyDown={(event) => handleKeyDown(event, recipe.ingredients.length)}
                      ref={inputRef as any}
                      onBlur={closeInput}
                      style={{ width: '80%', textAlign: 'center' }}
                    />
                  ) : (
                    <b onClick={() => inputClickHandler(recipe.ingredients.length)}>{` ${calculateTotalGr()} gr`}</b>
                  )
                }
              </td>
            </tr>
          </tbody>
        </table>

        <input
          type="range"
          min="0.1"
          max="4"
          step="0.01"
          value={multiplier}
          className="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer dark:bg-blue-700"
          style={{ marginTop: 10, marginBottom: 10 }}
          onChange={(event) => setMultiplier(Number(event.target.value))}
        />
      </div>

      {
        recipe.steps.length > 0 && (
          <div className="py-3 px-6 border-t border-gray-300">
            <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">Pasos</h5>
            <ol className="list-decimal">
              {recipe.steps.map((step) => <li>{step}</li>)}
            </ol>
          </div>
        )
      }

      {
        recipe.notes.length > 0 && (
          <div className="py-3 px-6 border-t border-gray-300">
            <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">Notas</h5>
            <ul className="list-disc">
              {recipe.notes.map((note) => <li>{note}</li>)}
            </ul>
          </div>
        )
      }
    </div>
  );
}

export default Recipe;
