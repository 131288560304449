import React, { useState } from 'react';
import './App.css';
import data from '../data';
import Recipe from './Recipe';

const App = () => {
  const [search, setSearch] = useState('');

  return (
    <div className="app">
      <div className="search-container">
        <input
          autoFocus
          type="text"
          className="form-control w-full block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          placeholder="Buscar"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>

      <div className="recipes-container">
        {
          data
            .filter((recipe) => recipe.name.toLowerCase().match(search.toLowerCase()))
            .map((recipe) => <Recipe recipe={recipe} />)
        }
      </div>
    </div>
  );
}

export default App;
