import { Recipe } from './types';

const data: Recipe[] = [
  {
    name: 'Pan hamburguesa',
    ingredients: [
      { name: 'Harina 000', amount: 1000, unit: 'grams' },
      { name: 'Sal', amount: 20, unit: 'grams' },
      { name: 'Levadura seca', amount: 10, unit: 'grams' },
      { name: 'Azucar', amount: 100, unit: 'grams' },
      { name: 'Agua', amount: 250, unit: 'grams' },
      { name: 'Leche', amount: 250, unit: 'grams' },
      { name: 'Manteca', amount: 100, unit: 'grams' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Pan flauta para choripan',
    ingredients: [
      { name: 'Harina 000', amount: 1000, unit: 'grams' },
      { name: 'Sal', amount: 20, unit: 'grams' },
      { name: 'Levadura seca', amount: 10, unit: 'grams' },
      { name: 'Extracto de malta', amount: 1, unit: 'spoon' },
      { name: 'Agua', amount: 600, unit: 'grams' },
    ],
    steps: [],
    notes: [
      '70gr cada bollo',
      'Hornear con vapor',
    ],
  },
  {
    name: 'Chimichurri',
    ingredients: [
      { name: 'Ajo', amount: 1, unit: 'part' },
      { name: 'Perejil', amount: 3, unit: 'part' },
      { name: 'Oregano', amount: 3, unit: 'part' },
      { name: 'Aji molido', amount: 1, unit: 'part' },
      { name: 'Sal', amount: 1, unit: 'spoon' },
      { name: 'Pimienta', amount: 1, unit: 'spoon' },
      { name: 'Aceite', amount: 3, unit: 'part' },
      { name: 'Vinagre de vino', amount: 1, unit: 'part' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Budin de banana',
    ingredients: [
      { name: 'Harina leudante', amount: 240, unit: 'grams' },
      { name: 'Manteca', amount: 110, unit: 'grams' },
      { name: 'Azucar', amount: 150, unit: 'grams' },
      { name: 'Banana', amount: 3, unit: 'part' },
      { name: 'Huevos', amount: 2, unit: 'part' },
      { name: 'Nueces', amount: 100, unit: 'grams' },
      { name: 'Bicarbonato', amount: 1, unit: 'spoon' },
      { name: 'Esencia de vainilla', amount: 1, unit: 'spoon' },
    ],
    steps: [
      'Batir manteca y azucar a blanco',
      'Integrar huevos y harina en 2 etapas',
      'Mezclar la banana, esencia de vainilla y nueces en otro bowl e integrar',
    ],
    notes: [],
  },
  {
    name: 'Pan lactal',
    ingredients: [
      { name: 'Harina 000', amount: 700, unit: 'grams' },
      { name: 'Harina integral', amount: 300, unit: 'grams' },
      { name: 'Sal', amount: 20, unit: 'grams' },
      { name: 'Azucar', amount: 100, unit: 'grams' },
      { name: 'Manteca', amount: 100, unit: 'grams' },
      { name: 'Leche', amount: 550, unit: 'grams' },
      { name: 'Levadura seca', amount: 4, unit: 'grams' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Tang Zhong',
    ingredients: [
      { name: 'Leche', amount: 5, unit: 'part' },
      { name: 'Harina', amount: 1, unit: 'part' },
    ],
    steps: [
      'Revolver en sarten hasta que espese',
      'Enfirar en heladera con film en contacto',
    ],
    notes: [],
  },
  {
    name: 'Pan masa madre V1',
    ingredients: [
      { name: 'Agua', amount: 290, unit: 'grams' },
      { name: 'Masa madre', amount: 80, unit: 'grams' },
      { name: 'Harina integral', amount: 45, unit: 'grams' },
      { name: 'Harina 000', amount: 375, unit: 'grams' },
      { name: 'Sal', amount: 8, unit: 'grams' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Focaccia',
    ingredients: [
      { name: 'Harina 000', amount: 500, unit: 'grams' },
      { name: 'Agua', amount: 350, unit: 'grams' },
      { name: 'Levadura fresca', amount: 12, unit: 'grams' },
      { name: 'Sal', amount: 8, unit: 'grams' },
      { name: 'Aceite de oliva', amount: 10, unit: 'grams' },
    ],
    steps: [],
    notes: [
      'Hacer pliegues mientras leuda',
      'Fermentar por lo menos 12 hs en la heladera',
    ],
  },
  {
    name: 'Tortilla de papas',
    ingredients: [
      { name: 'Papa', amount: 1000, unit: 'grams' },
      { name: 'Cebolla', amount: 1.5, unit: 'part' },
      { name: 'Huevos', amount: 10, unit: 'part' },
      { name: 'Sal', amount: 10, unit: 'grams' },
    ],
    steps: [
      'Cocinar la papa a fuego medio en aceite (ideal 70 grados)',
      'Cuando la papa se pone tierna agregar la cebolla y tapar',
      'Cuando la cebolla se cocina subir el fuego para que se dore',
      'Poner la preparación en un bowl (excurrida) y salar',
      'Incorporar los huevos y cocinar en una sarten bien caliente',
    ],
    notes: [
      'Papa en cuadrados',
      'Cebolla corte pluma',
    ],
  },
  {
    name: 'Pizza napoletana',
    ingredients: [
      { name: 'Harina 000', amount: 1000, unit: 'grams' },
      { name: 'Agua', amount: 550, unit: 'grams' },
      { name: 'Levadura fresca', amount: 3, unit: 'grams' },
      { name: 'Sal', amount: 22, unit: 'grams' },
    ],
    steps: [],
    notes: [
      'Leudar en bloque 2 o 3 horas',
      '1 día en la heladera',
      'Bollar y dejar reposar 3hs',
      'Hasta 28 gramos de sal y 580 de agua',
    ],
  },
  {
    name: 'Fugazzeta',
    ingredients: [
      { name: 'Harina 000', amount: 1000, unit: 'grams' },
      { name: 'Agua', amount: 550, unit: 'grams' },
      { name: 'Levadura fresca', amount: 3, unit: 'grams' },
      { name: 'Sal', amount: 22, unit: 'grams' },
      { name: 'Mozzarela', amount: 300, unit: 'grams' },
      { name: 'Queso parmesano', amount: 50, unit: 'grams' },
      { name: 'Pimienta', amount: 10, unit: 'grams' },
      { name: 'Jamon', amount: 200, unit: 'grams' },
      { name: 'Cebolla', amount: 0, unit: 'grams' },
      { name: 'Aceite de oliva', amount: 0, unit: 'grams' },
    ],
    steps: [],
    notes: [
      'Cuando cubris el relleno hacer unos agujeros y mojar con aceite de oliva',
      'Cocinar las cebollas con aceite, sal y pimienta antes y excurrir',
    ],
  },
  {
    name: 'Goulash',
    ingredients: [
      { name: 'Roast beef', amount: 1000, unit: 'grams' },
      { name: 'Cebolla', amount: 1000, unit: 'grams' },
      { name: 'Extracto de tomate', amount: 1, unit: 'spoon' },
      { name: 'Vino tinto', amount: 1, unit: 'part' },
      { name: 'Sal', amount: 1, unit: 'part' },
      { name: 'Paprika', amount: 1, unit: 'part' },
      { name: 'Curry', amount: 0.33, unit: 'part' },
      { name: 'Pimienta', amount: 1, unit: 'part' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Spatzle',
    ingredients: [
      { name: 'Leche', amount: 100, unit: 'grams' },
      { name: 'Huevos', amount: 4, unit: 'part' },
      { name: 'Harina', amount: 400, unit: 'grams' },
      { name: 'Soda', amount: 100, unit: 'grams' },
      { name: 'Sal', amount: 1, unit: 'part' },
      { name: 'Pimienta', amount: 1, unit: 'part' },
      { name: 'Nuez moscada', amount: 1, unit: 'part' },
    ],
    steps: [
      'Cuando suben cortar la cocción con agua fria',
      'Pasar a un bowl con aceite',
      'Saltear con manteca',
    ],
    notes: [],
  },
  {
    name: 'Hummus',
    ingredients: [
      { name: 'Garbanzo', amount: 2, unit: 'cup' },
      { name: 'Tahini', amount: 6, unit: 'spoon' },
      { name: 'Ajo', amount: 2, unit: 'part' },
      { name: 'Agua cocción', amount: 1, unit: 'spoon' },
      { name: 'Sal', amount: 1, unit: 'spoon' },
      { name: 'Limon', amount: 1, unit: 'spoon' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Mac & cheese',
    ingredients: [
      { name: 'Manteca', amount: 50, unit: 'grams' },
      { name: 'Harina 0000', amount: 50, unit: 'grams' },
      { name: 'Leche', amount: 750, unit: 'grams' },
      { name: 'Quesos', amount: 300, unit: 'grams' },
      { name: 'Fideos', amount: 1, unit: 'part' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Flan',
    ingredients: [
      { name: 'Huevos', amount: 6, unit: 'part' },
      { name: 'Yemas', amount: 6, unit: 'part' },
      { name: 'Azucar', amount: 250, unit: 'grams' },
      { name: 'Esencia de vainilla', amount: 1, unit: 'part' },
      { name: 'Leche', amount: 1000, unit: 'grams' },
      { name: 'Caramelo', amount: 1, unit: 'part' },
    ],
    steps: [
      'Batir huevos, yemas y azucar hasta romper estructura sin agregar aire',
      'Colar al meterlo en la flanera',
      'Cocinar al horno en baño maría con temperatura baja (150 grados) por 1 hora',
    ],
    notes: [],
  },
  {
    name: 'Galletitas de manteca',
    ingredients: [
      { name: 'Manteca', amount: 70, unit: 'grams' },
      { name: 'Harina 0000', amount: 250, unit: 'grams' },
      { name: 'Azucar', amount: 85, unit: 'grams' },
      { name: 'Huevo', amount: 1, unit: 'part' },
      { name: 'Esencia de vainilla', amount: 1, unit: 'spoon' },
      { name: 'Sal', amount: 1, unit: 'part' },
    ],
    steps: [
      'Mezclar el azucar, huevo, esencia de vainilla y sal hasta obtener una pasta liquida',
      'Agregar la harina',
      'Agregar la manteca',
    ],
    notes: [],
  },
  {
    name: 'Pan arabe',
    ingredients: [
      { name: 'Harina', amount: 500, unit: 'grams' },
      { name: 'Agua', amount: 270, unit: 'grams' },
      { name: 'Sal', amount: 10, unit: 'grams' },
      { name: 'Levadura fresca', amount: 10, unit: 'grams' },
      { name: 'Oliva', amount: 30, unit: 'grams' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Pan pebete',
    ingredients: [
      { name: 'Harina 000', amount: 500, unit: 'grams' },
      { name: 'Azucar', amount: 40, unit: 'grams' },
      { name: 'Levadura fresca', amount: 25, unit: 'grams' },
      { name: 'Agua', amount: 250, unit: 'grams' },
      { name: 'Manteca', amount: 40, unit: 'grams' },
      { name: 'Extracto de malta', amount: 10, unit: 'grams' },
      { name: 'Leche en polvo', amount: 30, unit: 'grams' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Pan de papa',
    ingredients: [
      { name: 'Levadura fresca', amount: 10, unit: 'grams' },
      { name: 'Harina 000', amount: 200, unit: 'grams' },
      { name: 'Agua', amount: 190, unit: 'grams' },
      { name: 'Papa', amount: 150, unit: 'grams' },
      { name: 'Leche en polvo', amount: 30, unit: 'grams' },
      { name: 'Azucar', amount: 30, unit: 'grams' },
      { name: 'Harina 0000', amount: 300, unit: 'grams' },
      { name: 'Manteca', amount: 50, unit: 'grams' },
      { name: 'Sal', amount: 10, unit: 'grams' },
    ],
    steps: [
      'Cocinar la papa con piel y pelar cocida. Usar fría.',
    ],
    notes: [],
  },
  {
    name: 'Arroz sushi',
    ingredients: [
      { name: 'Vinagre de arroz', amount: 150, unit: 'grams' },
      { name: 'Azucar', amount: 125, unit: 'grams' },
      { name: 'Sal', amount: 20, unit: 'grams' },
    ],
    steps: [],
    notes: [
      'La cantidad de vinagre es 1/3 de la de arroz',
    ],
  },
  {
    name: 'Lemon pie base',
    ingredients: [
      { name: 'Harina 0000', amount: 300, unit: 'grams' },
      { name: 'Azucar', amount: 60, unit: 'grams' },
      { name: 'Sal', amount: 1, unit: 'spoon' },
      { name: 'Manteca', amount: 180, unit: 'grams' },
      { name: 'Huevo', amount: 1, unit: 'part' },
      { name: 'Agua fria', amount: 15, unit: 'grams' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Lemon pie relleno',
    ingredients: [
      { name: 'Leche', amount: 300, unit: 'grams' },
      { name: 'Fecula', amount: 60, unit: 'grams' },
      { name: 'Azucar', amount: 150, unit: 'grams' },
      { name: 'Jugo de limón', amount: 100, unit: 'grams' },
      { name: 'Ralladura de limón', amount: 1, unit: 'part' },
      { name: 'Yema', amount: 4, unit: 'part' },
      { name: 'Manteca', amount: 80, unit: 'grams' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Merengue italiano',
    ingredients: [
      { name: 'Clara', amount: 150, unit: 'grams' },
      { name: 'Azucar', amount: 300, unit: 'grams' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Chocotorta',
    ingredients: [
      { name: 'Dulce de leche', amount: 1000, unit: 'grams' },
      { name: 'Crema de leche', amount: 600, unit: 'grams' },
      { name: 'Sal', amount: 2, unit: 'grams' },
    ],
    steps: [],
    notes: [
      'Humedecer las chocolinas hasta poder borrar la marca con el dedo.',
    ],
  },
  {
    name: 'Pan dulce V1',
    ingredients: [
      { name: 'Harina 000', amount: 1000, unit: 'grams' },
      { name: 'Azucar', amount: 200, unit: 'grams' },
      { name: 'Yemas', amount: 100, unit: 'grams' },
      { name: 'Sal', amount: 20, unit: 'grams' },
      { name: 'Miel', amount: 1, unit: 'spoon' },
      { name: 'Leche', amount: 200, unit: 'grams' },
      { name: 'Manteca', amount: 400, unit: 'grams' },
    ],
    steps: [
      'Mezclar las yemas con el azucar y un poco de leche para que se disuelva el azucar.',
    ],
    notes: [
      'La cantidad de leche es la necesaria para que la masa este bien hidratada.',
    ],
  },
  {
    name: 'Pan dulce V2',
    ingredients: [
      { name: 'Harina 000', amount: 800, unit: 'grams' },
      { name: 'Levadura seca', amount: 20, unit: 'grams' },
      { name: 'Leche', amount: 80, unit: 'grams' },
      { name: 'Crema', amount: 150, unit: 'grams' },
      { name: 'Manteca', amount: 200, unit: 'grams' },
      { name: 'Huevos', amount: 5, unit: 'part' },
      { name: 'Azucar', amount: 150, unit: 'grams' },
      { name: 'Sal', amount: 16, unit: 'grams' },
      { name: 'Extracto de malta', amount: 1, unit: 'spoon' },
      { name: 'Esencia de vainilla', amount: 1, unit: 'spoon' },
      { name: 'Agua de azahar', amount: 1, unit: 'spoon' },
      { name: 'Ralladura de limón', amount: 1, unit: 'part' },
    ],
    steps: [
      'Hacer el prefermento con la leche, levadura y una parte de la harina.',
    ],
    notes: [
      'Mezclar el azucar con la ralladura de limón para que tome sabor.',
      'Los aromas agregarlos a los huevos previamente para que tomen sabor.'
    ],
  },
  {
    name: 'Pan dulce V3',
    ingredients: [
      { name: 'Harina 000', amount: 1000, unit: 'grams' },
      { name: 'Azucar', amount: 210, unit: 'grams' },
      { name: 'Huevos', amount: 300, unit: 'grams' },
      { name: 'Sal', amount: 20, unit: 'grams' },
      { name: 'Miel', amount: 30, unit: 'grams' },
      { name: 'Leche', amount: 210, unit: 'grams' },
      { name: 'Manteca', amount: 360, unit: 'grams' },
    ],
    steps: [
      'Hacer una masa previa con 200gr de harina, 100 de leche y 20 de levadura.',
      'Mezclar las yemas con el azucar y un poco de leche para que se disuelva el azucar.',
    ],
    notes: [],
  },
  {
    name: 'Lemoncello',
    ingredients: [
      { name: 'Limones', amount: 9, unit: 'part' },
      { name: 'Alcohol de melaza', amount: 1000, unit: 'milliliters' },
      { name: 'Agua', amount: 1500, unit: 'milliliters' },
      { name: 'Azúcar', amount: 700, unit: 'grams' },
    ],
    steps: [
      'Tomar la cáscara de los limones sin la parte blanca',
      'Marinar las cáscaras en el alcohol por 20 días en un frasco hermetico',
      'Hervir el agua y agregar el azúcar',
      'Cuando se enfría colocar el líquido de los limones filtrado y mezclar bien',
    ],
    notes: [],
  },
  {
    name: 'Tiramisu',
    ingredients: [
      { name: 'Agua', amount: 60, unit: 'grams' },
      { name: 'Azucar', amount: 200, unit: 'grams' },
      { name: 'Yemas', amount: 5, unit: 'part' },
      { name: 'Mascarpone', amount: 500, unit: 'grams' },
      { name: 'Crema', amount: 400, unit: 'grams' },
      { name: 'Café', amount: 400, unit: 'milliliters' },
      { name: 'Vainillas', amount: 20, unit: 'part' },
    ],
    steps: [
      'Almibar: calentar el agua y azucar hasta los 121 grados sin tocar (punto bolita blanda)',
      'Aparato bomba: airear las yemas, luego tirar el almibar en hilo y batir hasta que enfríe',
      'Mezclar la crema y el mascarpone hasta un punto parecido a la crema chantilly',
      'Mezclar las preparaciones',
      'Mojar las vainillas en café (vuelta y vuelta)',
    ],
    notes: [],
  },
  {
    name: 'Salsa Big Mac',
    ingredients: [
      { name: 'Mayonesa', amount: 1, unit: 'cup' },
      { name: 'Mostaza', amount: 2, unit: 'spoon' },
      { name: 'Relish', amount: 4, unit: 'spoon' },
      { name: 'Vinagre de alcohol', amount: 0.5, unit: 'spoon' },
      { name: 'Ajo en polvo', amount: 0.33, unit: 'spoon' },
      { name: 'Cebolla en polvo', amount: 0.33, unit: 'spoon' },
      { name: 'Paprika', amount: 0.33, unit: 'spoon' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Salsa Mil Islas',
    ingredients: [
      { name: 'Mayonesa', amount: 4, unit: 'spoon' },
      { name: 'Ketchup', amount: 2, unit: 'spoon' },
      { name: 'Mostaza', amount: 1, unit: 'spoon' },
      { name: 'Relish', amount: 1, unit: 'spoon' },
      { name: 'Vinagre de alcohol', amount: 1, unit: 'spoon' },
      { name: 'Pimienta', amount: 0.33, unit: 'spoon' },
      { name: 'Pimentón', amount: 0.33, unit: 'spoon' },
      { name: 'Sal', amount: 1, unit: 'pinch' },
      { name: 'Azucar', amount: 1, unit: 'pinch' },
      { name: 'Ajo en polvo', amount: 1, unit: 'pinch' },
    ],
    steps: [],
    notes: [],
  },
  {
    name: 'Masa alfajor',
    ingredients: [
      { name: 'Harina 0000', amount: 300, unit: 'grams' },
      { name: 'Cacao amargo', amount: 30, unit: 'grams' },
      { name: 'Manteca', amount: 200, unit: 'grams' },
      { name: 'Azucar impalpable', amount: 100, unit: 'grams' },
      { name: 'Huevo', amount: 1, unit: 'part' },
      { name: 'Yema', amount: 1, unit: 'part' },
    ],
    steps: [
      'Arenar sin el huevo en la procesadora',
    ],
    notes: [],
  },
  {
    name: 'Ganache de chocolate',
    ingredients: [
      { name: 'Chocolate semiamargo', amount: 200, unit: 'grams' },
      { name: 'Manteca', amount: 200, unit: 'grams' },
    ],
    steps: [],
    notes: [],
  },
];

export default data;
